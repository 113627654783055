import React from 'react';
import {ThemeConsumer} from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faMoon } from '@fortawesome/free-solid-svg-icons';



export default function ToggleMode() {
	
		return (
			<ThemeConsumer>
				{theme => (
					<button className="toggleThemeButton" onClick={e=>
				        theme.setTheme(
				          theme.mode === 'dark'
				           ? {mode: 'light' }
				            : {mode: 'dark' }
				            )
				      }
				      >
				       {theme.mode === 'dark' ? (<FontAwesomeIcon icon={faLightbulb} />) : (<FontAwesomeIcon icon={faMoon} />)}

				      </button>
				)}
			</ThemeConsumer>
		);
	
}

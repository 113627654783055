/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { storage } from 'local-storage-fallback';
import { Link } from "gatsby";
import useTheme from "./useTheme";
import Header from "./header";
import ToggleMode from "./toggleMode";
import "./layout.css"
import "./test.scss"
  
const GlobalStyle = createGlobalStyle `
body {
  background-color: ${props =>
    // 
    props.theme.mode === 'dark' ? '#38383c' : '#f8f8f8'};
  color: ${props =>
    props.theme.mode === 'dark' ? '#EEE' : '#111'};
}

header {
  background-color: ${props =>
    props.theme.mode === 'dark' ? '#202222' : '#eeeae7'};
  color: ${props =>
    props.theme.mode === 'dark' ? '#EEEAE7' : '#111'};
}
header a {
  color: ${props => 
    props.theme.mode === 'dark' ? '#eeeae7' : '#202222'
  }
}
.toggleThemeButton {
  background-color: ${props =>
    props.theme.mode === 'dark' ? '#202222' : '#eeeae7'};
  color: ${props =>

    props.theme.mode === 'dark' ? '#eeeae7' : '#111'};
}

.projectHead {
  color: ${props =>
    props.theme.mode === 'dark' ? '#eeeae7' : '#202222'
  };

}

.learnContainer {
  background-color: ${props =>
    props.theme.mode === 'dark' ? '#202222' : '#eeeae7'
  };

}
.smallerTextOrange {
  color: ${props =>
    props.theme.mode === 'dark' ? '#FFA500' : '#523500'
  };

}



.sectionTitle.Sub {
  color:${props =>
    props.theme.mode === 'dark' ? '#ffd700' : '#eeeae7'};
}

p {
  color:${props =>
    props.theme.mode === 'dark' ? '#EEEAE7' : '#202222'};
}

.linkStyle a {
  color: ${props =>
    props.theme.mode === 'dark' ? '#EEEAE7' : '#202222'};
}

hr {
  background-color: ${props=>
    props.theme.mode === 'dark' ? '#EEEAE7' : '#202222'
  };
}

.card {
  background-color: ${props =>
    props.theme.mode === 'dark' ? '#202222' : '#eeeae7' 
  };
}
`;


// function getInitialTheme() {
//   const savedTheme = storage.getItem('theme')
//   return savedTheme ? JSON.parse(savedTheme) : { mode: 'dark'}
// }

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const theme = useTheme();
  // useEffect(() =>{
  //   storage.setItem('theme', JSON.stringify(theme))
  // }, 
  // [theme]
  // );

  return (
    <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
{/*      <p>{theme.mode}</p>*/}
    
      <ToggleMode/>
    

      <div
        style={{
          margin: `0 auto`,
          // maxWidth: 960,
          // padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer className="footerStyles">
          © {new Date().getFullYear()}, Robert Hammond
          {` `}
        </footer>
      </div>
      
    </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout




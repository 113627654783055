import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
const Header = ({ siteTitle }) => (
  <header
    style={{
      
      marginBottom: ``,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 className="navTitle" style={{ margin: 0, fontSize: `1.5em`, }}>
        <Link cover direction="right" duration={0.5} bg="#111111"
          to="/"
          style={{
            color: ``,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
        

      </h1>
      
      
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
